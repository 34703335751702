import React from "react";

function IconInstagram(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      stroke="currentColor"
      strokeWidth="0"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        stroke="none"
        d="M14.5 0h-13C.675 0 0 .675 0 1.5v13c0 .825.675 1.5 1.5 1.5h13c.825 0 1.5-.675 1.5-1.5v-13c0-.825-.675-1.5-1.5-1.5zM11 2.5c0-.275.225-.5.5-.5h2c.275 0 .5.225.5.5v2c0 .275-.225.5-.5.5h-2a.501.501 0 01-.5-.5v-2zM8 5a3.001 3.001 0 010 6 3.001 3.001 0 010-6zm6 8.5v0c0 .275-.225.5-.5.5h-11a.501.501 0 01-.5-.5v0V7h1.1A4.999 4.999 0 008 13a4.999 4.999 0 004.9-6H14v6.5z"
      ></path>
    </svg>
  );
}

export default IconInstagram;